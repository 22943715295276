.el-dialog__body {
  padding: 10px;
}
.el-dialog__header {
  padding: 0px;
}
.el-dialog__footer {
  padding: 5px;
  border-top: 1px solid #ccc;
}
.el-dialog__headerbtn {
  font-size: 20px;
  background: white;
  top: 5px !important;
}
.item-three {
  width: 32%;
  color: #409EFF;
}
